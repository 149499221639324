export const useHeadMeta = () => {
	const metaStore = useMeatStore();

	const { title, metaData, linkData } = storeToRefs(metaStore);

	useHead({
		title: title.value,
		meta: [...metaData.value],
		link: [...linkData.value]
	});
};
